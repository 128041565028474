.redhat-footer {
  background-color: $black;

  .grid-wrapper { padding: .5rem 0; }

  .licence {
    grid-column: 5/9;
    align-self: center;
    justify-self: center;
    a {
      color: $white;
      font-size: .75rem;
      font-weight: 200;
    }
    @media screen and (max-width: 1024px) {
      grid-column: 1/5;
      justify-self: left;
    }
    @media screen and (max-width: 480px) {
      grid-column: 1/13;
      justify-self: center;
      order: 3;
    }
  }

  .redhat {
    grid-column: 9/12;
    align-self: center;
    justify-self: end;
    font-size: .75rem;
    font-weight: 200;
    @media screen and (max-width: 1024px) {
      grid-column: 5/12;
      justify-self: right;
    }
    @media screen and (max-width: 480px) {
      grid-column: 1/13;
      justify-self: center;
      order: 2;
    }
  }
  .redhat-logo {
    grid-column: 12/13;
    justify-self: end;
    align-self: center;
    img { width: 6rem; }
    @media screen and (max-width: 480px) {
      grid-column: 1/13;
      justify-self: center;
      order: 1;
    }
  }
}
