// PROJECT styles to overwrite global.scss


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono');

html,
body {
  font-family: 'Open Sans', Arial, sans-serif;
  background-color: $black;
}

* {
  font-family: 'Open Sans', Arial, sans-serif;
  color: $white;
  line-height: 1rem;
}

h1 {
  font-size: 3rem;
  line-height: 3.75rem;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
  }
}

h3 {
  font-size: 1.5rem;
  line-height: 1.8rem;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
}

h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 400;
  @media screen and (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

h5 {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 700;
}

h6 {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 700;
}

p {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
}

a {
  line-height: 1.5rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  color: $link;
  &:hover, &:active, &:focus {
    color: $link-hover;
  }
}

a.button-cta,
button,
input[type="submit"] {
  color: #09131E;
  background-color: $light-blue;
  border: 2px solid $light-blue;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2.5;
  transition: background .2s ease-in 0s;
  text-decoration: none;
  &:hover, &:active, &:focus {
    background-color: $dark-blue;
    border-color: $light-blue;
    color: $light-blue;
    -webkit-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1rem;
    padding: .75rem 1rem;
  }
}

a.button-cta.secondary {
  background: transparent;
  border: 2px solid $light-blue;
  color: $light-blue;
  &:hover, &:active, &:focus {
    background-color: $light-blue;
    border-color: $light-blue;
    color: $dark-blue;
    -webkit-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.75);
  }
}

table {
  border-collapse: collapse;

  thead th,
  tbody th {
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: $dark-blue;
    font-weight: 400;
    text-align: left;
    padding: 1rem;
    background-color: $light-blue;
    border: 1px solid $light-blue;
    > div,
    > div > span {
      color: $dark-blue;
    }
  }

  tbody td {
    padding: .25rem 1rem;
    border: 1px solid $light-blue;
  }

  tbody tr {
    &:nth-child(even) {
      background-color: $dark-blue;
    }
    td p {
       margin: 0;
    }
  }
}

.smallrye-homepage {
 padding: 0;
}

.full-width-bg {
  margin: 0 -13rem;
  padding: 0 13rem;
  @media screen and (max-width: 1170px) {
    margin: 0 -6rem;
    padding: 0 6rem;
  }
  @media screen and (max-width: 480px) {
    margin: 0 -2rem;
    padding: 0 2rem;
  }
}

code, pre {
  background-color: $black;
  color: $code-color;
  overflow: auto;
}
