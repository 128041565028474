body.blog,
body.post {
  background-image: url($baseurl + '/assets/images/bg-home_trim.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.blog-page {
  .blog-list-item {
    margin-bottom: 5rem;
    p {
      margin: 0;
    }

    .post-title {
      h2 {
        margin: 0;
        a{
          color: $light-blue;
          text-decoration: none;
          font-weight: 100;
          text-transform: uppercase;
        }
      }
    }
  }
  .byline-wrapper {
    display: flex;
    align-items:center;
  }
  .headshot, .byline {
    display: inline;
  }
  .headshot {
    max-width: 2.5rem;
    border-radius: 1.5rem;
    margin-right: .5rem;
  }
  .share-page {
    text-align: right;
  }
}

@media screen and (max-width: $breakpoint-m) {
  .blog-list-item .share-page {
    text-align: left;
  }
  .blog-page p.byline {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
.blog-page,
.post-page {
  margin-bottom: 6rem;
}
