.used-by-band {
  background-color: $black;
  h2 {
    color: $light-blue;
  }
  .block {
    text-align: center;
    a {
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}
